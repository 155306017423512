/**
 * @generated SignedSource<<47837f8f62b6e9df76f08ed08044ca13>>
 * @relayHash 7f7647a1de646a63d88403a2179c7f98
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID app-buyer-finding/488.0.0-2024-11-12T19:25:11.001Z/RecentlyViewedItemsContextQuery

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RecentlyViewedItemsContextQuery$variables = {
  count?: number | null;
  excludeItemPks?: ReadonlyArray<string | null> | null;
  fetchUserData?: boolean | null;
  itemIds?: ReadonlyArray<string | null> | null;
  loadPortfolioData?: boolean | null;
  selectedItemIds?: ReadonlyArray<string | null> | null;
  userId: string;
  userIds?: ReadonlyArray<string | null> | null;
};
export type RecentlyViewedItemsContextQuery$data = {
  readonly viewer: {
    readonly itemRead: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"RecentlyViewedItemsCarousel_items">;
    } | null> | null;
    readonly user?: {
      readonly " $fragmentSpreads": FragmentRefs<"RecentlyViewedItemsCarousel_user">;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"RecentlyViewedItemsCarousel_viewer">;
  };
};
export type RecentlyViewedItemsContextQuery = {
  response: RecentlyViewedItemsContextQuery$data;
  variables: RecentlyViewedItemsContextQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": 0,
  "kind": "LocalArgument",
  "name": "count"
},
v1 = {
  "defaultValue": ([]/*: any*/),
  "kind": "LocalArgument",
  "name": "excludeItemPks"
},
v2 = {
  "defaultValue": false,
  "kind": "LocalArgument",
  "name": "fetchUserData"
},
v3 = {
  "defaultValue": ([]/*: any*/),
  "kind": "LocalArgument",
  "name": "itemIds"
},
v4 = {
  "defaultValue": false,
  "kind": "LocalArgument",
  "name": "loadPortfolioData"
},
v5 = {
  "defaultValue": ([]/*: any*/),
  "kind": "LocalArgument",
  "name": "selectedItemIds"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userId"
},
v7 = {
  "defaultValue": ([]/*: any*/),
  "kind": "LocalArgument",
  "name": "userIds"
},
v8 = [
  {
    "kind": "Variable",
    "name": "userId",
    "variableName": "userId"
  }
],
v9 = {
  "kind": "Variable",
  "name": "excludeItemPks",
  "variableName": "excludeItemPks"
},
v10 = {
  "kind": "Variable",
  "name": "userIds",
  "variableName": "userIds"
},
v11 = [
  {
    "kind": "Variable",
    "name": "itemIds",
    "variableName": "itemIds"
  }
],
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isAuctionEnabled",
  "storageKey": null
},
v13 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ConvertedAmountList",
    "kind": "LinkedField",
    "name": "convertedAmountList",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "amount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "currency",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "AuctionSolution",
  "kind": "LinkedField",
  "name": "relevantAuctionSolution",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasReserve",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "reserveMet",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AuctionBidsConnection",
      "kind": "LinkedField",
      "name": "bids",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalResults",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FinancialAmount",
      "kind": "LinkedField",
      "name": "highestBidAmount",
      "plural": false,
      "selections": (v13/*: any*/),
      "storageKey": null
    },
    (v14/*: any*/)
  ],
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "AuctionLot",
  "kind": "LinkedField",
  "name": "relevantAuctionLot",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "FinancialAmount",
      "kind": "LinkedField",
      "name": "buyItNowPrice",
      "plural": false,
      "selections": (v13/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FinancialAmount",
      "kind": "LinkedField",
      "name": "openingBid",
      "plural": false,
      "selections": (v13/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FinancialAmount",
      "kind": "LinkedField",
      "name": "reservePrice",
      "plural": false,
      "selections": (v13/*: any*/),
      "storageKey": null
    },
    (v14/*: any*/)
  ],
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ecommerceTrackingParams",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "contemporaryTrackingString",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "size",
      "value": "small"
    }
  ],
  "kind": "ScalarField",
  "name": "firstPhotoWebPath",
  "storageKey": "firstPhotoWebPath(size:\"small\")"
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pdpURL",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "browseUrl",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "categoryCode",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serviceId",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "categoryPath",
  "storageKey": null
},
v26 = [
  (v24/*: any*/),
  (v14/*: any*/)
],
v27 = {
  "alias": null,
  "args": null,
  "concreteType": "Seller",
  "kind": "LinkedField",
  "name": "seller",
  "plural": false,
  "selections": (v26/*: any*/),
  "storageKey": null
},
v28 = {
  "kind": "Literal",
  "name": "portfolioTypes",
  "value": [
    "HEART"
  ]
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "RecentlyViewedItemsContextQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "condition": "fetchUserData",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": (v8/*: any*/),
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  {
                    "args": [
                      {
                        "kind": "Variable",
                        "name": "count",
                        "variableName": "count"
                      },
                      (v9/*: any*/)
                    ],
                    "kind": "FragmentSpread",
                    "name": "RecentlyViewedItemsCarousel_user"
                  }
                ],
                "storageKey": null
              }
            ]
          },
          {
            "args": [
              {
                "kind": "Variable",
                "name": "loadPortfolioData",
                "variableName": "loadPortfolioData"
              },
              {
                "kind": "Variable",
                "name": "selectedItemIds",
                "variableName": "selectedItemIds"
              },
              (v10/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "RecentlyViewedItemsCarousel_viewer"
          },
          {
            "alias": null,
            "args": (v11/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "itemRead",
            "plural": true,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "RecentlyViewedItemsCarousel_items"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v6/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/),
      (v5/*: any*/),
      (v4/*: any*/),
      (v7/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "RecentlyViewedItemsContextQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "condition": "fetchUserData",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": (v8/*: any*/),
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": [
                      (v9/*: any*/),
                      {
                        "kind": "Variable",
                        "name": "first",
                        "variableName": "count"
                      }
                    ],
                    "concreteType": "RecentHistoryItemsConnection",
                    "kind": "LinkedField",
                    "name": "recentHistoryItems",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "RecentHistoryItemsEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Item",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v12/*: any*/),
                              (v15/*: any*/),
                              (v16/*: any*/),
                              (v17/*: any*/),
                              (v18/*: any*/),
                              (v19/*: any*/),
                              (v20/*: any*/),
                              (v21/*: any*/),
                              (v22/*: any*/),
                              (v23/*: any*/),
                              (v24/*: any*/),
                              (v25/*: any*/),
                              (v27/*: any*/),
                              (v14/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v14/*: any*/)
                ],
                "storageKey": null
              }
            ]
          },
          {
            "condition": "loadPortfolioData",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "itemIds",
                    "variableName": "selectedItemIds"
                  },
                  (v28/*: any*/),
                  (v10/*: any*/)
                ],
                "concreteType": "PortfolioItemV2",
                "kind": "LinkedField",
                "name": "portfolioItemMatches",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "portfolioId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "portfolioItemId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Item",
                    "kind": "LinkedField",
                    "name": "item",
                    "plural": false,
                    "selections": (v26/*: any*/),
                    "storageKey": null
                  },
                  (v14/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": "userPortfolios",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 1
                  },
                  (v28/*: any*/),
                  (v10/*: any*/)
                ],
                "concreteType": "PortfoliosConnection",
                "kind": "LinkedField",
                "name": "portfolios",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PortfoliosEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PortfolioV2",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": (v26/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ]
          },
          {
            "alias": null,
            "args": (v11/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "itemRead",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v12/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/),
                  (v20/*: any*/),
                  (v21/*: any*/),
                  (v22/*: any*/),
                  (v23/*: any*/),
                  (v24/*: any*/),
                  (v25/*: any*/),
                  (v27/*: any*/)
                ],
                "type": "Item",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v14/*: any*/)
                ],
                "type": "Node",
                "abstractKey": "__isNode"
              }
            ],
            "storageKey": null
          },
          (v14/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "app-buyer-finding/488.0.0-2024-11-12T19:25:11.001Z/RecentlyViewedItemsContextQuery",
    "metadata": {},
    "name": "RecentlyViewedItemsContextQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "5d4da5b1f788fc2394bf70de3f797629";

export default node;
