import { QP_PAGE_SIZE_VALUE_DEFAULT, QP_PAGE_SIZE_VALUE_MAX } from './queryParamConstants';

export const MIN_THIN_PAGE_COUNT = 30;
export const RESULTS_FIRST_ROW_COUNT = 4;
export const SWIPER_TABLET_TILES_COUNT = 3.5;
export const MAX_AD_LIMIT = 4;
export const PAGE_SIZE_OPTIONS = [
    { size: QP_PAGE_SIZE_VALUE_DEFAULT, adLimit: 2 },
    { size: QP_PAGE_SIZE_VALUE_MAX, adLimit: MAX_AD_LIMIT },
];

export const FILTER_NAME_CREATOR = 'creator';
export const FILTER_SEARCH_TYPE = 'searchType';
export const SEARCH_TYPES = {
    NONE: 'none',
    SEMANTIC: 'semantic',
    CLASSIFIED: 'classified',
    ENTITY: 'entity',
    FUZZY: 'fuzzy',
};

export const MIN_RELATED_PAGES = 2;
export const MAX_RELATED_PAGES = 12;

export const INITIAL_VISIBLE_INTERIOR_ARTICLES = 3;
export const INITIAL_VISIBLE_RELATED_CREATORS = 3;
export const REVEAL_RELATED_CREATORS_COUNT_DESKTOP = 6;
export const REVEAL_RELATED_CREATORS_COUNT_MOBILE = 3;

export const INITIAL_VISIBLE_QA_ITEMS = 5;
export const EXPANDED_VISIBLE_QA_ITEMS = 20;

export const LINKS_BLOCK_DATA_TN = 'links-block';

export const SortMap = {
    recommended: 'recommended',
    newest: 'newest',
    buyPageRelevance: 'buyPageRelevance',
    relevance: 'relevance',
    priceAsc: 'price-high',
    priceDesc: 'price-low',
    featured: 'featured',
    popular: 'popular',
};
